import { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import JoinIn from "./components/JoinIn";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Login from "./components/Login";
import LoginEmail from "./components/LoginEmail";
import Footer from "./components/Footer";
import MyPage from "./components/MyPage";
import InquiryBoard from "./components/InquiryBoard";
import InquiryDetail from "./components/InquiryDetail";
import InquiryCreate from "./components/InquiryCreate";
import TaskManagement from "./components/TaskManagement";
import AddTask from "./components/AddTask";
import EditTask from "./components/EditTask";
import HowToUse from "./components/HowToUse";
import Download from "./components/Download";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./components/LanguageSwitcher";
import UserStatus from "./components/UserStatus";

const LanguageRoute: FC = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/joinIn" element={<JoinIn />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login-email" element={<LoginEmail />} />
      <Route path="/mypage" element={<MyPage />} />
      <Route path="/inquiries" element={<InquiryBoard />} />
      <Route path="/inquiries/new" element={<InquiryCreate />} />
      <Route path="/inquiries/:inquiryId" element={<InquiryDetail />} />
      <Route path="/task-management" element={<TaskManagement />} />
      <Route path="/task-management/add" element={<AddTask />} />
      <Route path="/task-management/edit" element={<EditTask />} />
      <Route path="/how-to-use" element={<HowToUse />} />
      <Route path="/download" element={<Download />} />
    </Routes>
  );
};

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // 화면 전체 높이를 채움
        }}
      >
        <Router>
          <LanguageSwitcher />
          <UserStatus />
          <main
            style={{
              flex: 1, // 메인 콘텐츠 영역을 남은 공간에 맞춤
              overflow: "auto", // 스크롤 가능하도록 설정
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/en" replace />} />
              <Route path="/:lang/*" element={<LanguageRoute />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
