import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";
import styles from "./NavigationLinks.module.css";

const NavigationLinks: React.FC = () => {
  const { userDetails } = useUser() ?? {};
  const userId = userDetails ? userDetails.id : "Guest";
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기

  return (
    <div className={styles.links}>
      <Link to={`/${currentLang}/`} className={styles.link}>
        <img
          src="/assets/images/wide_logo.png"
          alt="autovid_wide_logo"
          width={150}
        />
      </Link>

      <Link to={`/${currentLang}/download`} className={styles.link}>
        {t("navigation.download")}
      </Link>

      {/* HowToUse 메뉴 추가 */}
      <Link to={`/${currentLang}/how-to-use`} className={styles.link}>
        {t("navigation.how_to_use")}
      </Link>

      <Link to={`/${currentLang}/inquiries`} className={styles.link}>
        {t("navigation.inquiries")}
      </Link>
      {userId !== "Guest" && (
        <Link to={`/${currentLang}/task-management`} className={styles.link}>
          {t("navigation.task_management")}
        </Link>
      )}
    </div>
  );
};

export default NavigationLinks;
