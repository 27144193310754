import React from "react";
import styles from "./HomePage.module.css";
import { useTranslation } from "react-i18next"; // i18next import
import { Link } from "react-router-dom"; // react-router-dom에서 Link import

function HomePage() {
  const { t, i18n } = useTranslation(); // useTranslation 훅 사용
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const sections = [
    <div className={`${styles.sectionWrapper} ${styles.evenSection}`} key={0}>
      <div className={styles.imageContainer}>
        <img src="/assets/images/titleimage_00.png" alt="AUTOVID 0" />
      </div>
      <div className={styles.textContainer}>
        <p>{t("home_page.sections.section0.part1")}</p>
        <p>{t("home_page.sections.section0.part2")}</p>
        <p>{t("home_page.sections.section0.part3")}</p>
        <p>{t("home_page.sections.section0.part4")}</p>
      </div>
    </div>,
    <div className={`${styles.sectionWrapper} ${styles.oddSection}`} key={1}>
      <div className={styles.textContainer}>
        <p>{t("home_page.sections.section1.part1")}</p>
        <p>{t("home_page.sections.section1.part2")}</p>
        <p>{t("home_page.sections.section1.part3")}</p>
        <p>{t("home_page.sections.section1.part4")}</p>
        <p>{t("home_page.sections.section1.part5")}</p>
      </div>
      <div className={styles.imageContainer}>
        <img src="/assets/images/titleimage_01.png" alt="AUTOVID 1" />
      </div>
    </div>,
    <div className={`${styles.sectionWrapper} ${styles.evenSection}`} key={2}>
      <div className={styles.imageContainer}>
        <img src="/assets/images/titleimage_02.png" alt="AUTOVID 2" />
      </div>
      <div className={styles.textContainer}>
        <p>{t("home_page.sections.section2.part1")}</p>
        <p>{t("home_page.sections.section2.part2")}</p>
        <p>{t("home_page.sections.section2.part3")}</p>
        <p>{t("home_page.sections.section2.part4")}</p>
      </div>
    </div>,
    // 추가된 문의사항 세그먼트
    <div className={`${styles.sectionWrapper} ${styles.oddSection}`} key={3}>
      <div className={styles.imageContainer}>
        <img src="/assets/images/titleimage_04.png" alt="AUTOVID 2" />
      </div>
      <div className={styles.textContainer}>
        <p>{t("home_page.sections.section3.part1")}</p>
        <p>{t("home_page.sections.section3.part2")}</p>
        <p>{t("home_page.sections.section3.part3")}</p>
        <Link to={`/${currentLang}/inquiries`}>
          {t("home_page.inquiry_link")}
        </Link>
      </div>
    </div>,
  ];

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.topSection}>
        {sections.map((section, index) => (
          <div key={index}>{section}</div>
        ))}
      </div>
      <div>
        <iframe
          width="700"
          height="394"
          src="https://www.youtube.com/embed/t19Yj1WYP7k"
          title="AUTOVID Introduction"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.bottomSection}>
        {/* 요금제 표시 섹션 */}
        <table className={styles.pricingTable}>
          <thead>
            <tr>
              <th>{t("home_page.pricing_table.features")}</th>
              <th>{t("home_page.pricing_table.pricing")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.youtube_download")}
              </td>
              <td>{t("home_page.pricing_table.unlimited")}</td>
            </tr>
            <tr>
              <td data-label="Features">{t("home_page.pricing_table.tts")}</td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.bgm_insert")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.subtitle_creation")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.shorts_export")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.manual_video_creation")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.best_series_manual")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.remote_control")}
              </td>
              <td>{t("home_page.pricing_table.free")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.advanced_tts")}
              </td>
              <td>{t("home_page.pricing_table.credit_required")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.automatic_video_creation")}
              </td>
              <td>{t("home_page.pricing_table.credit_required")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.best_series_auto")}
              </td>
              <td>{t("home_page.pricing_table.credit_required")}</td>
            </tr>
            <tr>
              <td data-label="Features">
                {t("home_page.pricing_table.ad_removal")}
              </td>
              <td>{t("home_page.pricing_table.pro_or_credit_ad_removal")}</td>
            </tr>
            <tr className={styles.proSubscriptionRow}>
              <td data-label="Features">
                {t("home_page.pricing_table.pro_subscription")}
              </td>
              <td>
                <strong>
                  {t("home_page.pricing_table.pro_subscription_details")}
                </strong>
                <ul className={styles.proBenefits}>
                  <li>{t("home_page.pricing_table.pro_benefit_1")}</li>
                  <li>{t("home_page.pricing_table.pro_benefit_2")}</li>
                  <li>{t("home_page.pricing_table.pro_benefit_3")}</li>
                  <li>{t("home_page.pricing_table.pro_benefit_4")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.smallText}>
                {t("home_page.pricing_table.small_text_1")}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className={styles.smallText}>
                {t("home_page.pricing_table.small_text_2")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HomePage;
