import React from "react";
import styles from "./AutoVideoGeneration.module.css";
import { useTranslation } from "react-i18next";

const AutoVideoGeneration: React.FC = () => {
  const { i18n, t } = useTranslation();

  // 현재 언어 코드 가져오기
  const currentLang = i18n.language;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("how_to_use_bestseries.title")}</h2>
      <div className={styles.stepList}>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step1")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_01.png`}
            alt="Step 1"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step2")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_02.png`}
            alt="Step 2"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step3")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_03.png`}
            alt="Step 3"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step4")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_04.png`}
            alt="Step 4"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step5")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_05.png`}
            alt="Step 5"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step6")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_06.png`}
            alt="Step 6"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step7")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_07.png`}
            alt="Step 7"
            className={styles.image}
          />
        </div>
        <div className={styles.step}>
          <p className={styles.description}>
            {t("how_to_use_bestseries.step8")}
          </p>
          <img
            src={`/assets/images/${currentLang}/bestseries_08.png`}
            alt="Step 8"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoVideoGeneration;
