import React from "react";
import styles from "./Download.module.css";

const Download: React.FC = () => {
  const downloadUrl =
    "https://apps.microsoft.com/store/detail/9N4Z3Q5N10QR?cid=DevShareMCLPCS";
  const downloadImage = "/assets/images/DownloadBadgeSmall.png";

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.downloadSection}>
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={downloadImage}
              alt="Download"
              className={styles.downloadImage}
            />
          </a>
          <p className={styles.description}>
            Click the image above to download the application.
          </p>
        </div>
      </main>
    </div>
  );
};

export default Download;
