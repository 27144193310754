import React, { useState } from "react";
import styles from "./HowToUse.module.css";
import AutoVideoGeneration from "./AutoVideoGeneration";
import BestSeries from "./BestSeries";

const HowToUse: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<"auto" | "best">("auto");

  return (
    <div className={styles.container}>
      <div className={styles.header}>How to Use</div>
      <div className={styles.content}>
        <nav className={styles.nav}>
          <ul>
            <li
              className={selectedMenu === "auto" ? styles.active : ""}
              onClick={() => setSelectedMenu("auto")}
            >
              Auto Video Generation
            </li>
            <li
              className={selectedMenu === "best" ? styles.active : ""}
              onClick={() => setSelectedMenu("best")}
            >
              Best Series
            </li>
          </ul>
        </nav>
        <main className={styles.main}>
          {selectedMenu === "auto" && <AutoVideoGeneration />}
          {selectedMenu === "best" && <BestSeries />}
        </main>
      </div>
    </div>
  );
};

export default HowToUse;
